<template>
  <div class="grid">
    <div class="col-12">
      <div class="mb-2">
      <router-link
          :to="{name: 'spectacleCrud'}"
      ><i class="pi pi-arrow-left"/> Retour
      </router-link>
        </div>
      <div class="card">
        <Toast/>
        <h5>Spectacle - {{this.spectacle.name}}</h5>

        <CommonSpectacleReservationList :reservation-filters="this.reservationFilters"></CommonSpectacleReservationList>

      </div>
    </div>
  </div>


</template>

<script>
import SpectacleService from "@/service/SpectacleService";
import SpectacleReservationService from "@/service/SpectacleReservationService";
import CommonSpectacleReservationList from '@components/CommonSpectacleReservationList';

export default {
  components: {CommonSpectacleReservationList},
  data() {
    return {
      spectacle: {},
      reservations: [],
      loadingReservations: false,
      reservationFilters: {
      spectacleId: this.$route.params.spectacleId
    }
    }
  },

  spectacleService: null,
  spectacleReservationService: null,
  created() {
    this.spectacleService = new SpectacleService();
    this.spectacleReservationService = new SpectacleReservationService();
  },

  methods: {
  },

  computed: {
  },

  mounted() {
    this.loadingReservations = true;
    this.spectacleService.spectacle(this.$route.params.spectacleId).then(data => {
      this.spectacle = data;
    });
  },

}
</script>

<style scoped>

</style>